import PollWorkerAssignmentStatusReport from "../../../components/Reports/PollWorkerAssignmentStatusReport";
import { getPrecinctsForElection, getVotingLocationsForElection } from "../../../fetchers";
import React, { useEffect, useState } from "react";
import { PollworkerModuleInfo, Precinct, VotingLocation } from "../../../types";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";
import { SectionSubHeading, SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { Button } from "shared/src/components/ui";
import store from "admin/src/store";
import { MODAL_ACTION_TYPES, MODALS } from "../../../actions/modal";
import PollWorkerSignInSheetReport from "../../../components/Reports/PollWorkerSignInSheetReport";
import {Nomenclature} from "../../../components/Nomenclature";

export default function Reports() {
  // Get data: http://localhost:3000/api/ReportsApi/LoadReports
  const [precinctList, setPrecinctList] = useState<Precinct[]>([]);
  const [locationList, setLocationList] = useState<VotingLocation[]>([]);
  const [moduleInfo, setModuleInfo] = useState<PollworkerModuleInfo>();

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, responseData: string) => {
    if (from === 'System' && eventName === 'Init' && eventValue === 'Response') {
      const info = JSON.parse(atob(responseData));
      setModuleInfo(info);
    }
  });

  useEffect(() => {
    if (!moduleInfo) return;

    const electionId = moduleInfo.ElectionId;

    getPrecinctsForElection(electionId).then(result => {
      setPrecinctList(result.data);
    })

    getVotingLocationsForElection(electionId).then(result => {
      setLocationList(result.data);
    })

  }, [moduleInfo]);

  return (
    <>
      <SectionSubHeading className="my-2 border-b pb-3 mb-4">Available Reports</SectionSubHeading>
      <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-md border">
        <SectionSubSubHeading className="font-medium text-gray-800">
          <Nomenclature nomenclatureId="PollWorkerDisplayName"/> Assignment Status Report
        </SectionSubSubHeading>
        <Button
          onClick={() => {
            store.dispatch({
              type: MODAL_ACTION_TYPES.SHOW,
              payload: MODALS.PW_ASSIGNMENT_REPORT,
            })
          }}>Generate
        </Button>
      </div>
      <PollWorkerAssignmentStatusReport
        precincts={precinctList}
        locations={locationList} />
      <div className="mt-2 flex items-center justify-between p-4 bg-white rounded-lg shadow-md border">
        <SectionSubSubHeading className="font-medium text-gray-800">
          <Nomenclature nomenclatureId="PollWorkerDisplayName"/> Sign-In Sheet Report
        </SectionSubSubHeading>
        <Button
          onClick={() => {
            store.dispatch({
              type: MODAL_ACTION_TYPES.SHOW,
              payload: MODALS.PW_SIGNIN_SHEET_REPORT,
            })
          }}>Generate
        </Button>
      </div>
      <PollWorkerSignInSheetReport
        precincts={precinctList}
        locations={locationList} />
    </>
  );
}
