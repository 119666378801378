import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../reducers";
import {useTranslation} from "react-i18next";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        nomenclature: {
          "VotingLocationDisplayName": "Voting Location",
          "PrecinctDisplayName": "Precinct",
          "EmployeeDisplayName": "Employee",
          "TaskDisplayName": "Task",
          "ProjectDisplayName": "Project",
          "InventoryDisplayName": "Inventory",
          "DeploymentWorkerDisplayName": "Deployment Worker",
          "PollWorkerDisplayName": "PollWorker",
          "VoterDisplayName": "Voter",
          "LnaDisplayName": "L & A",
          "LnaTestDisplayName": "L & A Test",
          "EfProjectContactDisplayName": "Ef Project Contact",
          "OfficerDisplayName": "Officer",
          "FilingClerkDisplayName": "Filing Clerk",
          "CampaignFinanceDisplayName": "Campaign Finance",
          "AbsenteeByMailDisplayName": "Absentee By Mail",
          "EarlyVotingDisplayName": "Early Voting",
          "PwTrainingDisplayName": "Poll Worker Training",
          "PwSkillsDisplayName": "Poll Worker Skills",
          "PwRolesDisplayName": "Poll Worker Roles",
          "ThirdPartyDisplayName": "Third Party",
          "PollworkerElectionDayDisplayName": "Poll Worker Election Day",
          "PollworkerStandbyDisplayName": "Poll Worker Standby",
          "PollworkerTraineeDisplayName": "Poll Worker Trainee",
          "PollworkerProspectDisplayName": "Poll Worker Prospect",
          "PollworkerApplicantDisplayName": "Poll Worker Applicant",
          "PollworkerEmployeeIdDisplayName": "Poll Worker Employee Id",
          "PollworkerOnlineSystemDisplayName": "Poll Worker Online System",
          "DistrictComboNumberDisplayName": "District Combo Number",
          "CardStyleDisplayName": "Card Style",
          "EquipGroupDisplayName": "Equipment Group",
          "PrecinctCodeDisplayName": "Precinct Code",
          "PrecinctManagerDisplayName": "Precinct Manager",
          "VoterRegNumberDisplayName": "Voter Reg Number",
          "BallotAcceptedDisplayName": "Ballot Accepted",
          "FilerTypeDisplayName": "Filer Type",
          "CandidateDisplayName": "Candidate",
          "CFVoterRegNoDisplayName": "CF Voter Reg No",
          "CommitteeDisplayName": "Committee",
          "ChairPersonDisplayName": "Chair Person",
          "CommitteeExpirationDateDisplayName": "Committee Expiration Date",
          "OfficeTypeDisplayName": "Office Type",
          "FilerIdDisplayName": "Filer Id",
          "PINDisplayName": "PIN",
          "FirstElectionYearDisplayName": "First Election Year",
          "CFDateApprovedDisplayName": "CF Date Approved",
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export interface NomenclatureProps {
  nomenclatureId: string;
}

export function Nomenclature({nomenclatureId}: NomenclatureProps) {
  const customerAccount = useSelector((state: AppState) => state.user?.account);
  const { t } = useTranslation();

  useEffect(() => {
    i18next.addResources('en', 'nomenclature', {'VotingLocationDisplayName': customerAccount?.votingLocationDisplayName});
    i18next.addResources('en', 'nomenclature', {'PrecinctDisplayName': customerAccount?.precinctDisplayName});
    i18next.addResources('en', 'nomenclature', {'EmployeeDisplayName': customerAccount?.employeeDisplayName});
    i18next.addResources('en', 'nomenclature', {'TaskDisplayName': customerAccount?.taskDisplayName});
    i18next.addResources('en', 'nomenclature', {'ProjectDisplayName': customerAccount?.projectDisplayName});
    i18next.addResources('en', 'nomenclature', {'InventoryDisplayName': customerAccount?.inventoryDisplayName});
    i18next.addResources('en', 'nomenclature', {'DeploymentWorkerDisplayName': customerAccount?.deploymentWorkerDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollWorkerDisplayName': customerAccount?.pollWorkerDisplayName});
    i18next.addResources('en', 'nomenclature', {'VoterDisplayName': customerAccount?.voterDisplayName});
    i18next.addResources('en', 'nomenclature', {'LnaDisplayName': customerAccount?.lnaDisplayName});
    i18next.addResources('en', 'nomenclature', {'LnaTestDisplayName': customerAccount?.lnaTestDisplayName});
    i18next.addResources('en', 'nomenclature', {'EfProjectContactDisplayName': customerAccount?.efProjectContactDisplayName});
    i18next.addResources('en', 'nomenclature', {'OfficerDisplayName': customerAccount?.officerDisplayName});
    i18next.addResources('en', 'nomenclature', {'FilingClerkDisplayName': customerAccount?.filingClerkDisplayName});
    i18next.addResources('en', 'nomenclature', {'CampaignFinanceDisplayName': customerAccount?.campaignFinanceDisplayName});
    i18next.addResources('en', 'nomenclature', {'AbsenteeByMailDisplayName': customerAccount?.absenteeByMailDisplayName});
    i18next.addResources('en', 'nomenclature', {'EarlyVotingDisplayName': customerAccount?.earlyVotingDisplayName});
    i18next.addResources('en', 'nomenclature', {'PwTrainingDisplayName': customerAccount?.pwTrainingDisplayName});
    i18next.addResources('en', 'nomenclature', {'PwSkillsDisplayName': customerAccount?.pwSkillsDisplayName});
    i18next.addResources('en', 'nomenclature', {'PwRolesDisplayName': customerAccount?.pwRolesDisplayName});
    i18next.addResources('en', 'nomenclature', {'ThirdPartyDisplayName': customerAccount?.thirdPartyDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerElectionDayDisplayName': customerAccount?.pollworkerElectionDayDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerStandbyDisplayName': customerAccount?.pollworkerStandbyDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerTraineeDisplayName': customerAccount?.pollworkerTraineeDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerProspectDisplayName': customerAccount?.pollworkerProspectDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerApplicantDisplayName': customerAccount?.pollworkerApplicantDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerEmployeeIdDisplayName': customerAccount?.pollworkerEmployeeIdDisplayName});
    i18next.addResources('en', 'nomenclature', {'PollworkerOnlineSystemDisplayName': customerAccount?.pollworkerOnlineSystemDisplayName});
    i18next.addResources('en', 'nomenclature', {'DistrictComboNumberDisplayName': customerAccount?.districtComboNumberDisplayName});
    i18next.addResources('en', 'nomenclature', {'CardStyleDisplayName': customerAccount?.cardStyleDisplayName});
    i18next.addResources('en', 'nomenclature', {'EquipGroupDisplayName': customerAccount?.equipGroupDisplayName});
    i18next.addResources('en', 'nomenclature', {'PrecinctCodeDisplayName': customerAccount?.precinctCodeDisplayName});
    i18next.addResources('en', 'nomenclature', {'PrecinctManagerDisplayName': customerAccount?.precinctManagerDisplayName});
    i18next.addResources('en', 'nomenclature', {'VoterRegNumberDisplayName': customerAccount?.voterRegNumberDisplayName});
    i18next.addResources('en', 'nomenclature', {'BallotAcceptedDisplayName': customerAccount?.ballotAcceptedDisplayName});
    i18next.addResources('en', 'nomenclature', {'FilerTypeDisplayName': customerAccount?.filerTypeDisplayName});
    i18next.addResources('en', 'nomenclature', {'CandidateDisplayName': customerAccount?.candidateDisplayName});
    i18next.addResources('en', 'nomenclature', {'CFVoterRegNoDisplayName': customerAccount?.cFVoterRegNoDisplayName});
    i18next.addResources('en', 'nomenclature', {'CommitteeDisplayName': customerAccount?.committeeDisplayName});
    i18next.addResources('en', 'nomenclature', {'ChairPersonDisplayName': customerAccount?.chairPersonDisplayName});
    i18next.addResources('en', 'nomenclature', {'CommitteeExpirationDateDisplayName': customerAccount?.committeeExpirationDateDisplayName});
    i18next.addResources('en', 'nomenclature', {'OfficeTypeDisplayName': customerAccount?.officeTypeDisplayName});
    i18next.addResources('en', 'nomenclature', {'FilerIdDisplayName': customerAccount?.filerIdDisplayName});
    i18next.addResources('en', 'nomenclature', {'PINDisplayName': customerAccount?.pINDisplayName});
    i18next.addResources('en', 'nomenclature', {'FirstElectionYearDisplayName': customerAccount?.firstElectionYearDisplayName});
    i18next.addResources('en', 'nomenclature', {'CFDateApprovedDisplayName': customerAccount?.cFDateApprovedDisplayName});
  }, [customerAccount]);

  return (
    <>{t(nomenclatureId, {ns: 'nomenclature'})}</>
  );
}
