import { FieldError, FieldErrorsImpl, Merge, useForm, useWatch } from "react-hook-form";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { Dropdown, Input, Textarea, Button } from "shared/src/components/ui";
import { StickyFooter } from "../../../components/Pollworker/Panels/ui/StickyFooter";
import { Flexor, Loading, TippyContent } from "shared/src/components";
import { Switch } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import { classNames } from "shared/src/utils/classNames";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef, useState } from "react";
import { fetchAddEditUserInfo, fetchUserIdIsInUse, saveAddEditUserInfo } from "../../../fetchers/setupAndAdmin";
import { ZodSchema, z } from 'zod';
import {Nomenclature} from "../../../components/Nomenclature";

const isNullOrWhitespace = (input: string | null | undefined) => !input || !input.trim();

export const saveEmployeeInfoDtoSchema: ZodSchema = z.object({
  evUserId: z.string().optional().nullable(),
  userId: z.string().min(1).max(100),
  firstName: z.string().min(1).max(100),
  lastName: z.string().min(1).max(100),
  email: z.string().email().max(300).optional().nullable().or(z.string().max(0).optional().nullable()),
  state: z.string().length(2).optional().nullable().or(z.string().max(0).optional().nullable()),
  securityLevelId: z.string(),
  cellPhone: z.string().regex(/(^$)|(^\d{10}$)/).optional().nullable(),
  okToSendSms: z.boolean().optional(),
  homePhone: z.string().regex(/(^$)|(^\d{10}$)/).optional().nullable(),
  address: z.string().max(100).optional().nullable(),
  address2: z.string().max(100).optional().nullable(),
  city: z.string().max(100).optional().nullable(),
  zipCode: z.string().max(10).regex(/(^$)|(^\d{10}$)|(^\d{5}$)/).optional().nullable(),
  notes: z.string().max(2048).optional().nullable(),
  voterRegistrationNumber: z.string().optional().nullable(),
  employeeId: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  ssoLoginId: z.string().max(255).optional().nullable(),
  ssoEnabled: z.boolean().optional(),
  enabled: z.boolean().optional(),
}).refine(validateUserIdUniqueAsync, {
  message: 'User ID is already in use',
  path: ['userId']
}).refine(({ ssoEnabled, ssoLoginId }) => !ssoEnabled || !isNullOrWhitespace(ssoLoginId), {
  message: 'SSO Login ID is required when SSO is enabled',
  path: ['ssoLoginId']
});

export type SaveEmployeeInfoDto = z.infer<typeof saveEmployeeInfoDtoSchema>;

export type SecurityLevel = {
  id: string;
  securityLevelName: string;
}

export type UsStateDialogDto = {
  fullName: string;
  abbreviation: string;
}

export type AddEditUserInfoDto = {
  user: SaveEmployeeInfoDto;
  securityLevels: SecurityLevel[];
  states: UsStateDialogDto[];
  ssoAllowed: boolean;
}

async function validateUserIdUniqueAsync(user: SaveEmployeeInfoDto): Promise<boolean>{
  const { evUserId, userId } = user;

  if (isNullOrWhitespace(userId)) {
    return false;
  }

  const isInUse: boolean = await fetchUserIdIsInUse(evUserId, userId);

  return !isInUse;
}

function ErrorLabel({error}: {error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined}) {
  if (!error) return null;

  const message = error?.message as string;

  return (<div className="text-ev-red text-xs">{message}</div>);
}

export default function AddEditUser({ evUserId, onComplete }: { evUserId?: string, onComplete?: Function }) {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null as AddEditUserInfoDto | null);

  useEffect(() => {
    fetchAddEditUserInfo(evUserId)
      .then((dto: AddEditUserInfoDto) => {
        setFormData(dto);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading && <Loading loadingMessage="Loading User Information..." />}
      {!isLoading && <AddEditUserForm dto={formData} onComplete={onComplete} />}
    </>
  );
}

export function AddEditUserForm({ dto, onComplete }: { dto: AddEditUserInfoDto | null, onComplete?: Function }) {
  const formRef = useRef<HTMLFormElement>(null);

  const addEditEmployee = dto?.user;
  const ssoAllowed = dto?.ssoAllowed;

  const securityLevels = dto?.securityLevels
    ?.map((sl: SecurityLevel) => ({label: sl.securityLevelName, value: sl.id}))
    ?? [];

  const usStates = dto?.states
    ?.map((us: UsStateDialogDto) => ({label: us.fullName, value: us.abbreviation}))
    ?? [];

  const { register, handleSubmit, formState: { errors, isSubmitting }, setValue, control } = useForm<SaveEmployeeInfoDto>({
    resolver: zodResolver(saveEmployeeInfoDtoSchema),
    defaultValues: addEditEmployee,
  });

  const enabled = useWatch({ name: 'enabled', control });
  const ssoEnabled = useWatch({ name: 'ssoEnabled', control });
  const okToSendSms = useWatch({ name: 'okToSendSms', control });
  const state = useWatch({ name: 'state', control });
  const securityLevelId = useWatch({ name: 'securityLevelId', control });

  async function save(user: any) {
    if (!user) return;

    saveAddEditUserInfo(user)
      .then(() => {
        if (onComplete) {
          onComplete();
        }
      });
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit((user) => save(user))}>
      <div>
        <div className="p-2">
          <SectionSubSubHeading className='-ml-2 mb-2'>
            <span className="bg-white px-2">System Information</span>
            <hr />
          </SectionSubSubHeading>
          <div className="flex flex-row justify-around gap-4">
            <div className="w-full">
              <label htmlFor="user-id" className="block text-sm font-medium leading-6 text-gray-900">
                User ID <span className="text-ev-red">*</span>
              </label>
              <Input
                type="text"
                id="user-id"
                placeholder='(User ID / Login ID)'
                {...register('userId')}
              />
              <ErrorLabel error={errors.userId} />
            </div>
            <div className="grow-0">
              <label htmlFor="name.prefix" className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">
                User Status
              </label>
              <Switch.Group as="div" className="flex items-center">
                <Tippy content={(<TippyContent>Specifies if account is enabled</TippyContent>)}>
                  <Switch
                    checked={enabled}
                    onChange={(value) => setValue('enabled', value)}
                    className={classNames(
                      enabled ? 'bg-ev-red' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2'
                    )}
                  >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                  </Switch>
                </Tippy>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Enabled</span>{' '}
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>

          {ssoAllowed && <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="sso-login-id" className="block text-sm font-medium leading-6 text-gray-900">
                SSO Login ID {ssoEnabled && <span className="text-ev-red">*</span>}
              </label>
              <Input
                type="text"
                id="sso-login-id"
                placeholder='(You Must Enter a Login ID to Enable SSO)'
                {...register('ssoLoginId')}
              />
              <ErrorLabel error={errors.ssoLoginId} />
            </div>
            <div className="grow-0">
              <label htmlFor="name.prefix" className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">
                SSO Status
              </label>
              <Switch.Group as="div" className="flex items-center">
                <Tippy content={(<TippyContent>Determines if SSO is enabled for this user</TippyContent>)}>
                  <Switch
                    checked={ssoEnabled}
                    onChange={(value) => setValue('ssoEnabled', value)}
                    className={classNames(
                      ssoEnabled ? 'bg-ev-red' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2'
                    )}
                  >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          ssoEnabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                  </Switch>
                </Tippy>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Enabled</span>{' '}
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>}

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="cell-phone" className="block text-sm font-medium leading-6 text-gray-900">
                Cell Phone
              </label>
              <Input
                type="tel"
                id="cell-phone"
                placeholder='(Cell Phone)'
                {...register('cellPhone')}
              />
              <ErrorLabel error={errors.cellPhone} />
            </div>
            <div className="grow-0">
              <label htmlFor="name.prefix" className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">
                OK to Send SMS
              </label>
              <Switch.Group as="div" className="flex items-center">
                <Tippy content={(<TippyContent>Determines if user can receive text messages.</TippyContent>)}>
                  <Switch
                    checked={okToSendSms}
                    onChange={(value) => setValue('okToSendSms', value)}
                    className={classNames(
                      okToSendSms ? 'bg-ev-red' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2'
                    )}
                  >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          okToSendSms ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                  </Switch>
                </Tippy>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Enabled</span>{' '}
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="security-level" className="block text-sm font-medium leading-6 text-gray-900">
                Security Level <span className="text-ev-red">*</span>
              </label>
              <Dropdown
                emptyPlaceholder='(Select A Security Level)'
                placeholder="(Select A Security Level)"
                options={securityLevels}
                selectedValue={securityLevelId}
                onChange={(event) => setValue('securityLevelId', event.target.value)}
              />
              <ErrorLabel error={errors.securityLevelId} />
            </div>
          </div>

        </div>

        <div className="p-2 mt-3">
          <SectionSubSubHeading className='-ml-2 mb-2 pt-0.5'>
            <span className="bg-white px-2">General Information</span>
            <hr />
          </SectionSubSubHeading>

          <div className="flex flex-row justify-around gap-4">
            <div className="w-full">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                First name <span className="text-ev-red">*</span>
              </label>
              <div>
                <Input
                  type="text"
                  id="first-name"
                  placeholder='(First Name)'
                  {...register('firstName')}
                />
                <ErrorLabel error={errors.firstName} />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Last name <span className="text-ev-red">*</span>
              </label>
              <div>
                <Input
                  type="text"
                  id="last-name"
                  placeholder='(Last Name)'
                  {...register('lastName')}
                />
                <ErrorLabel error={errors.lastName} />
              </div>
            </div>
            <div className="grow-0 min-w-32">
              <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                Title
              </label>
              <div>
                <Input
                  type="text"
                  id="title"
                  placeholder='(Title or Prefix)'
                  {...register('title')}
                />
                <ErrorLabel error={errors.title} />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="voter-registration-number" className="block text-sm font-medium leading-6 text-gray-900">
                <Nomenclature nomenclatureId="VoterRegNumberDisplayName"/>
              </label>
              <div>
                <Input
                  type="text"
                  id="voter-registration-number"
                  placeholder='(Voter Registration Number)'
                  {...register('voterRegistrationNumber')}
                />
                <ErrorLabel error={errors.voterRegistrationNumber} />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="employee-id" className="block text-sm font-medium leading-6 text-gray-900">
                <Nomenclature nomenclatureId="EmployeeDisplayName"/> ID
              </label>
              <div>
                <Input
                  type="text"
                  id="employee-id"
                  placeholder='(ID)'
                  {...register('employeeId')}
                />
                <ErrorLabel error={errors.employeeId} />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="email-address" className="block text-sm font-medium leading-6 text-gray-900">
                Email Address
              </label>
              <div>
                <Input
                  type="email"
                  id="email-address"
                  placeholder='(Email Address)'
                  {...register('email')}
                />
                <ErrorLabel error={errors.email} />
              </div>
            </div>
          </div>

        </div>

        <div className="p-2 mt-3">
          <SectionSubSubHeading className='-ml-2 mb-2 pt-0.5'>
            <span className="bg-white px-2">Communications</span>
            <hr />
          </SectionSubSubHeading>

          <div className="flex flex-row justify-around gap-4">
            <div className="w-full">
              <label htmlFor="home-phone" className="block text-sm font-medium leading-6 text-gray-900">
                Home Phone
              </label>
              <div>
                <Input
                  type="tel"
                  id="home-phone"
                  placeholder='(Home Phone)'
                  {...register('homePhone')}
                />
                <ErrorLabel error={errors.homePhone} />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                Address
              </label>
              <div>
                <Input
                  type="text"
                  id="address"
                  placeholder='(Address)'
                  {...register('address')}
                />
                <ErrorLabel error={errors.address} />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="address2" className="block text-sm font-medium leading-6 text-gray-900">
                Address 2
              </label>
              <div>
                <Input
                  type="text"
                  id="address2"
                  placeholder='(Address 2)'
                  {...register('address2')}
                />
                <ErrorLabel error={errors.address2} />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                City
              </label>
              <div>
                <Input
                  type="text"
                  id="city"
                  placeholder='(City)'
                  {...register('city')}
                />
                <ErrorLabel error={errors.city} />
              </div>
            </div>
          </div>



          <div className="flex flex-row justify-around gap-4 pt-3">
            <div className="w-full">
              <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                State
              </label>
              <div>
                <Dropdown
                  emptyPlaceholder='(Select A State)'
                  placeholder="(Select A State)"
                  options={usStates}
                  selectedValue={state}
                  onChange={(event) => setValue('state', event.target.value)}
                />
                <ErrorLabel error={errors.state} />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="zip-code" className="block text-sm font-medium leading-6 text-gray-900">
                Zip Code
              </label>
              <div>
                <Input
                  type="text"
                  id="zip-code"
                  placeholder='(Zip Code)'
                  {...register('zipCode')}
                />
                <ErrorLabel error={errors.zipCode} />
              </div>
            </div>
          </div>

        </div>

        <div className="p-2 mt-3">
          <SectionSubSubHeading className='-ml-2 mb-2 pt-0.5'>
            <span className="bg-white px-2">Notes</span>
            <hr />
          </SectionSubSubHeading>

          <div className="flex flex-row justify-around gap-4">
            <div className="w-full">
              <div>
                <Textarea
                  id="notes"
                  {...register('notes')}
                />
                <ErrorLabel error={errors.notes} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickyFooter className="mt-3">
        <Flexor justify='end'>
          <Button
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Flexor>
      </StickyFooter>
    </form>
  );
}
